import {
    TextField,
    DateField,
    TopToolbar,
    ListButton,
    Edit,
    Labeled,
    TextInput,
    BooleanInput,
    SelectInput,
    DateInput,
    TabbedForm,
    useTranslate,
    useRecordContext,
    FormDataConsumer, required, SaveButton, Toolbar, DeleteButton, SimpleShowLayout, BooleanField,
} from 'react-admin';

import {ChevronLeft} from '@mui/icons-material';
import React from "react";
import CodeInseeInput from "./widgets/CodeInseeInput";
import EvsesField from "./widgets/EvsesField";
import Box from "@mui/material/Box";
import {AppPermissions} from "../../../data/constants/permissions";
import {useIsAllowed} from "../../../../helpers/useIsAllowed";
import {Alert} from "@mui/material";

const Actions = () => {
    return (
        <TopToolbar>
            <ListButton icon={<ChevronLeft/>}/>
        </TopToolbar>
    );
};

const LocationEditToolbar = () => {
    const { isAllowed } = useIsAllowed();

    return (
        <Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
            <SaveButton/>
            {isAllowed(AppPermissions.ExploitationLocationsManage) && <DeleteButton />}
        </Toolbar>
    );
};

export const LocationEdit = () => {
    const { isAllowed, isAllowedAny } = useIsAllowed();

    return (
        <Edit actions={<Actions/>}>
            <TabbedForm toolbar={<LocationEditToolbar />}>
                <TabbedForm.Tab label="Location">
                    <h3>Details</h3>
                    <Labeled>
                        <TextField source="id"/>
                    </Labeled>
                    <Labeled>
                        <TextField source="name" label={'name'}/>
                    </Labeled>
                    <Labeled>
                        <TextField source="address"/>
                    </Labeled>
                    <Labeled>
                        <TextField source="postal_code"/>
                    </Labeled>
                    <Labeled>
                        <TextField source="city"/>
                    </Labeled>
                    <Labeled>
                        <TextField source="country"/>
                    </Labeled>
                    <Labeled>
                        <TextField source="latitude"/>
                    </Labeled>
                    <Labeled>
                        <TextField source="longitude"/>
                    </Labeled>
                    <Labeled>
                        <TextField source="type"/>
                    </Labeled>
                    <Labeled>
                        <DateField source="last_updated" showTime={true}/>
                    </Labeled>

                    <Box mt={3}>
                        <DateInput source="metaData.activationDate"/>
                    </Box>

                    <h3>Evses</h3>
                    <EvsesField source="evses"/>
                </TabbedForm.Tab>

                {isAllowedAny([AppPermissions.ExploitationLocationsOpenDataView, AppPermissions.ExploitationLocationsOpenDataEdit]) && <TabbedForm.Tab label={'Open data'}>
                    {isAllowed(AppPermissions.ExploitationLocationsOpenDataEdit) ? <OpenDataFieldsEdit/> : <OpenDataFieldsShow/>}
                </TabbedForm.Tab>}
            </TabbedForm>
        </Edit>
    );
};

const OpenDataFieldsShow = () => {
    const record = useRecordContext();
    const translate = useTranslate();

    if (!record?.openData) {
        return <Alert severity={'info'} sx={{marginBottom: 2}}>{translate('location.opendata.no_data')}</Alert>
    }

    return <SimpleShowLayout>
        <Alert severity={'info'} sx={{marginBottom: 2}}>{translate('location.opendata.introView')}</Alert>
        <TextField source={'openData.id_station_itinerance'} label={'location.opendata.field.id_station_itinerance.label'}/>
        <TextField source={'openData.implantation_station'} label={'location.opendata.field.implantation_station.label'}/>
        <TextField source={'openData.code_insee_commune'} label={'location.opendata.field.code_insee_commune.label'}/>
        <BooleanField source={'openData.gratuit'} label={'location.opendata.field.gratuit.label'}/>
        <BooleanField source={'openData.paiement_acte'} label={'location.opendata.field.paiement_acte.label'}/>
        <BooleanField source={'openData.paiement_cb'} label={'location.opendata.field.paiement_cb.label'}/>
        <BooleanField source={'openData.paiement_autre'} label={'location.opendata.field.paiement_autre.label'}/>
        <TextField source={'openData.tarification'} label={'location.opendata.field.tarification.label'}/>
        <TextField source={'openData.condition_acces'} label={'location.opendata.field.condition_acces.label'}/>
        <BooleanField source={'openData.reservation'} label={'location.opendata.field.reservation.label'}/>
        <TextField source={'openData.horaires'} label={'location.opendata.field.horaires.label'}/>
        <TextField source={'openData.accessibilite_pmr'} label={'location.opendata.field.accessibilite_pmr.label'}/>
        <TextField source={'openData.restriction_gabarit'} label={'location.opendata.field.restriction_gabarit.label'}/>
        <BooleanField source={'openData.station_deux_roues'} label={'location.opendata.field.station_deux_roues.label'}/>
        <TextField source={'openData.raccordement'} label={'location.opendata.field.raccordement.label'}/>
        <TextField source={'openData.date_mise_en_service'} label={'location.opendata.field.date_mise_en_service.label'}/>
        <TextField source={'openData.observations'} label={'location.opendata.field.observations.label'}/>
        <BooleanField source={'openData.enable_sync'} label={'location.opendata.field.enable_sync.label'}/>
    </SimpleShowLayout>
}

const OpenDataFieldsEdit = () => {
    const translate = useTranslate();
    const record = useRecordContext();

    if (!record) {
        return null;
    }

    const defaultIdStationItinerance = record.id.toString().replaceAll('*', '');

    return (<FormDataConsumer<{ openData?: { enable_sync?: string } }>>
        {({formData, ...rest}) => (
            <>
                <Alert severity={'info'} sx={{marginBottom: 2}}>{translate('location.opendata.introEdit')}</Alert>
                <TextInput source={'openData.id_station_itinerance'}
                           label={'location.opendata.field.id_station_itinerance.label'}
                           defaultValue={defaultIdStationItinerance}
                           {...(formData.openData?.enable_sync ? {validate: required()} : {})}
                />
                <SelectInput source={'openData.implantation_station'}
                             label={'location.opendata.field.implantation_station.label'}
                             choices={[
                                 'Voirie',
                                 'Parking public',
                                 'Parking privé à usage public',
                                 'Parking privé réservé à la clientèle',
                                 'Station dédiée à la recharge rapide'
                             ].map(el => ({id: el, name: el}))}
                             defaultValue={getOpenDataImplantationDefault(record.type)}
                             {...(formData.openData?.enable_sync ? {validate: required()} : {})}
                />
                <CodeInseeInput name={'openData.code_insee_commune'} source={'openData.code_insee_commune'}
                                label={'location.opendata.field.code_insee_commune.label'}/>
                <BooleanInput source={'openData.gratuit'} label={'location.opendata.field.gratuit.label'}
                              defaultValue={false}/>
                <BooleanInput source={'openData.paiement_acte'} label={'location.opendata.field.paiement_acte.label'}
                              defaultValue={false}
                              {...(formData.openData?.enable_sync ? {validate: required()} : {})}/>
                <BooleanInput source={'openData.paiement_cb'} label={'location.opendata.field.paiement_cb.label'}
                              defaultValue={false}/>
                <BooleanInput source={'openData.paiement_autre'} label={'location.opendata.field.paiement_autre.label'}
                              defaultValue={false}/>
                <TextInput source={'openData.tarification'} label={'location.opendata.field.tarification.label'}
                           parse={value => value}/>
                <SelectInput source={'openData.condition_acces'} label={'location.opendata.field.condition_acces.label'}
                             choices={[
                                 {id: 'Accès libre', name: 'Accès libre'},
                                 {id: 'Accès réservé', name: 'Accès réservé'},
                             ]}
                             defaultValue={'Accès réservé'}
                             {...(formData.openData?.enable_sync ? {validate: required()} : {})}
                />
                <BooleanInput source={'openData.reservation'} label={'location.opendata.field.reservation.label'}
                              defaultValue={true}
                              {...(formData.openData?.enable_sync ? {validate: required()} : {})}/>
                <SelectInput source={'openData.horaires'}
                             choices={[
                                 {id: '24/7', name: '7j/7 - 24h/24'},
                                 {
                                     id: 'Mo-Fr 08:00-12:00,Mo-Fr 14:00-17:00,Sa 09:00-12:00',
                                     name: 'Lundi au Vendredi 8H-12H 14H-17H + Samedi 9H 12H'
                                 },
                                 {id: 'Mo-Fr 08:00-12:00,Mo-Fr 14:00-17:00', name: 'Lundi au Vendredi 8H-12H 14H-17H'},
                             ]}
                             defaultValue={'24/7'}
                             label={'location.opendata.field.horaires.label'} {...(formData.openData?.enable_sync ? {validate: required()} : {})}/>
                <SelectInput source={'openData.accessibilite_pmr'}
                             choices={[
                                 {id: 'Réservé PMR', name: 'Réservé PMR'},
                                 {id: 'Accessible mais non réservé PMR', name: 'Accessible mais non réservé PMR'},
                                 {id: 'Non accessible', name: 'Non accessible'},
                                 {id: 'Accessibilité inconnue', name: 'Accessibilité inconnue'},
                             ]}
                             label={'location.opendata.field.accessibilite_pmr.label'}
                             defaultValue={'Accessibilité inconnue'}
                             {...(formData.openData?.enable_sync ? {validate: required()} : {})}/>
                <TextInput source={'openData.restriction_gabarit'}
                           label={'location.opendata.field.restriction_gabarit.label'}
                           {...(formData.openData?.enable_sync ? {validate: required()} : {})}
                           parse={value => value}/>
                <BooleanInput source={'openData.station_deux_roues'}
                              label={'location.opendata.field.station_deux_roues.label'} defaultValue={false}
                              {...(formData.openData?.enable_sync ? {validate: required()} : {})}/>
                <SelectInput source={'openData.raccordement'}
                             choices={[
                                 {id: 'Direct', name: 'Direct'},
                                 {id: 'Indirect', name: 'Indirect'},
                             ]}
                             label={'location.opendata.field.raccordement.label'}/>
                <DateInput source={'openData.date_mise_en_service'}
                           label={'location.opendata.field.date_mise_en_service.label'}/>
                <TextInput source={'openData.observations'} label={'location.opendata.field.observations.label'}
                           parse={value => value}/>

                <BooleanInput source={'openData.enable_sync'} label={'location.opendata.field.enable_sync.label'}/>
            </>
        )}
    </FormDataConsumer>);
}

const getOpenDataImplantationDefault = (locationValue: string) : string => {
    switch (locationValue) {
        case 'ON_STREET':
            return 'Voirie';
        case 'PARKING_LOT':
            return 'Parking privé à usage public';
    }

    return '';
}
