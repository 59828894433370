import {StringMap} from "ra-core/src/i18n/TranslationMessages";

const frMessages: StringMap = {
  country: "pays",
  badge: {
    error: {
      uid_is_not_unique: "Cet uid est déjà utilisé",
    },
  },
  location: {
    column: {
      opendata: {
        enable_sync: 'Sync opendata'
      }
    },
    opendata: {
      introEdit: "Spécifiez ici les valeurs de l'export opendata complémentaires des infos de la location transmises par le CPO",
      introView: "Visualisez ici les valeurs de l'export opendata complémentaires des infos de la location transmises par le CPO",
      no_data: "Pas encore de données opendata pour cette location",
      field: {
        id_station_itinerance: {
          label: 'id station itinerance',
        },
        implantation_station: {
          label: 'implantation station',
        },
        adresse_station: {
          label: 'adresse station',
        },
        gratuit: {
          label: 'gratuit',
        },
        paiement_acte: {
          label: 'paiement acte',
        },
        paiement_cb: {
          label: 'paiement cb',
        },
        paiement_autre: {
          label: 'paiement autre',
        },
        tarification: {
          label: 'tarification',
        },
        condition_acces: {
          label: 'condition d\'accès',
        },
        reservation: {
          label: 'reservation',
        },
        horaires: {
          label: 'horaires',
        },
        accessibilite_pmr: {
          label: 'accessibilite pmr',
        },
        restriction_gabarit: {
          label: 'restriction de gabarit',
        },
        station_deux_roues: {
          label: 'station deux roues',
        },
        raccordement: {
          label: 'raccordement',
        },
        date_mise_en_service: {
          label: 'date de mise en service',
        },
        observations: {
          label: 'observations',
        },
        code_insee_commune: {
          label: 'Code insee commune',
          not_found: 'Code insee de la commune non trouvé pour cette adresse'
        },
        enable_sync: {
          label: 'Integrer dans l\'export opendata'
        },
      }
    }
  },
  error: {
    form: {
      validation: "Le formulaire n'est pas valide",
    },
  },
  resources: {
    cdrs: {
      fields: {
        uid: "Badge",
        cdr: {
          start_date_time: "Début de charge",
          stop_date_time: "Fin de charge",
          total_cost: "Coût",
          total_energy: "Énergie",
          total_time: "Temps",
          total_parking_time: "Parking",
          last_updated: "last_updated",
        }
      },
      overrides: {
        cta: "Mettre à jour le coût",
        success: "Le coût a été mis à jour",
        dialog: {
          title: "Mettre à jour le coût",
          text: "Mettre à jour le coût",
        },
        card: {
          title: "Historique des modifications de coûts",
          table: {
            changes: "Modification(s)",
            updatedAt: "Mis à jour le",
            updatedBy: "Mis à jour par",
          }
        }
      },
      cta: {
        update_cost: "Mettre à jour le coût",
      }
    },
    "exploitation-locations": {
      fields: {
        type: "Type",
        "metaData.activationDate": "Date de mise en service",
      }
    },
    configuration: {
      pageTitle: "Configuration",
      fields : {
      },
      messages: {
        saved: "Configuration sauvegardée"
      },
      tabs: {
        permissions: "Permissions",
        cdrs: "CDRs"
      },
      cdrs: {
        no_tariff_fallbacks: {
          label: "Tarifs de substitution",
          description: "Si aucun tarif n'est spécifié pour un CDR, le cout sera calculé en comparant les valeurs suivantes au connecteur utilisé.",
        }
      },
      inputDescriptions: {
      },
      permissions: {
        permissions: {
          CdrsView: "CDRs - Voir les CDRs",
          CdrsEdit: "CDRs - Éditer/modifier les CDRs",
          CdrsSync: "CDRs - Gérer les synchronisations manuelles",
          CdrOverwriteCost: "CDRs - Modifier le coût d'un CDR",
          TariffGroupsView: "Tariffs - Voir les groupes de tarifs",
          TariffGroupsManage: "Tariffs - Gestion des groupes de tarifs",
          TariffLinksView: "Tariffs - Voir les tarifs links",
          TariffLinksManage: "Tariffs - Gestion des tarifs links",
          ExploitationManage: "Exploitation - Gestion de l'exploitation",
          ExploitationLocationsView: "Exploitation - Voir les locations",
          ExploitationLocationsManage: "Exploitation - Gestion des locations",
          ExploitationLocationsOpenDataView: "Exploitation - Voir les données opendata des locations",
          ExploitationLocationsOpenDataEdit: "Exploitation - Modifier les données opendata des locations",
          InvoicingAccess: "Invoicing - Accès à la facturation",
          ConfigManage: "Configuration - Gestion de la configuration",
          SuperAdmin: "Super Admin (donne implicitement toutes les permissions)",
        }
      }
    },
    tariffgroups: {
      name: "Groupe de tarifs |||| Groupes de tarifs",
      cta: {
        add_subtariff: "Ajouter un tarif",
        remove_subtariff: "Supprimer ce tarif",
        add_element: "Ajouter un élément",
        remove_element: "Supprimer cet élément",
        add_price_component: "Ajouter un composant de prix",
        remove_price_component: "Supprimer ce composant",
      },
      fields: {
        tariffgroup_id: "ID du groupe de tarifs",
        subtariffs: "Tarifs",
        "subtariffs.tariffkey": "Clé",
        "subtariffs.tariff.id": "Id tarif",
        "subtariffs.tariff.currency": "Devise",
        "subtariffs.tariff.elements.price_components.price": "Prix",
        "subtariffs.tariff.elements.price_components.step_size": "Incrément",
        "subtariffs.tariff.elements.price_components.type": "Type",
      },
      labels: {
        subtariffs: "Tarifs",
        elements: "Éléments"
      }
    }
  },
  cta: {
    cancel: "Annuler",
  },
  exploitation: {
    stats: {
      noData: "Pas de données",
      deployment: {
        section: 'Déploiement',
        signed: 'Points de charge signés',
        deployed: 'Points de charge déployés',
        removed: 'Points de charge retirés',
        table: {
          month: "Mois",
          previsional: "Prévu",
          adjusted: "Ajusté",
          real: "Réel",
          late: "Retard",
          note: "Commentaire",
        }
      },
      locations: {
        section: 'Statistiques des locations',
        totalLocation: 'Total locations',
        totalEVSE: 'Total EVSEs',
        totalAvailable: 'EVSEs disponibles',
        totalRemoved: 'Retirées',
        totalPlanned: 'Planifiées',
      },
      cdrs: {
        chartCost: "CA",
        chartSession: "Sessions",
        partySelect: "Party",
        toggleEMSP: "Par EMSP",
        toggleIssuer: "Par Issuer",
        noData: "Pas de données pour cette période",
        table: {
          totalCostLabel: "CA total",
          totalCostPercentLabel: "% du CA total",
          totalSessionLabel: "Nb Sessions",
          totalSessionPercentLabel: "% des sessions totales",
          meanTotalCostLabel: "CA moyen par session"
        }
      }
    },
    admin: {
      config: {
        fields: {
          recipients: 'Destinataires',
          email: 'Email',
          from: 'Expéditeur',
          prefix: 'Préfixe',
        }
      },
      resendStatsMailing: {
        cta: 'Renvoyer les stats',
        success: 'Le mail de stats a été renvoyé',
        dialog: {
          title: 'Renvoyer les stats',
          text: 'Renvoyer les stats à tous les destinataires. Vous pouvez si vous le désirez modifier le sujet du mail, et choisir une plage de dates (par défaut, les stats de la veille seront envoyées)',
          subject: 'Sujet du mail (optionel)',
          rangeFrom: "Date de début",
          rangeTo: "Date de fin",
          showRange: "Sélectionner la plage de dates",
          send: 'Envoyer',
        }
      },
      sendMailStatTest: {
        cta: 'Envoyer un mail test',
        success: 'Le mail de test a été envoyé',
        dialog: {
          title: 'Envoyer un mail de test',
          text: 'Envoyer un mail de test à une liste restreinte de destinataires',
          recipients: 'Destinataires',
          send: 'Envoyer',
        }
      },
    }
  },
  menu: {
    items: {
      exploitation: {
        submenu: {
          admin: "Admin",
        }
      },
      settings: "Configuration",
      statistics: {
        label: "Statistiques",
        submenu: {
          cdrs: "CDRs",
          deployment: "Déploiement",
          invoicing: "Pré-facturation",
          locations: "Locations",
        }
      }
    }
  },
};

export default frMessages;
